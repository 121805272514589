import React from "react";

const IndexPage = () => {
  if (typeof window !== 'undefined') {
    window.location.href = 'https://www.weve.app';
  }

  return (<div />);
};

export default IndexPage
